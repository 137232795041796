import React from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"
import Close from "../images/times.svg"

// Bind modal to App element
Modal.setAppElement(`#___gatsby`)

// Modal wrapper to reduce duplication of modal code
const ModalWrapper = ({ children, isOpen, toggleModal, label }) => (
  <Modal isOpen={isOpen} onRequestClose={toggleModal} contentLabel={label} overlayClassName="Overlay" className="Modal">
    <button
      className="modal-dismiss"
      onClick={event => toggleModal(event, false)}
    >
      <img src={Close} alt='Close icon - click to close the modal' />
    </button>
    <div className="modal-inner">{children}</div>
  </Modal>
)

ModalWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default ModalWrapper
