import React from "react"
import Autosuggest from "react-autosuggest"
import debounce from "lodash.debounce"
import axios from "axios"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { default as Modal } from "./modal"
import Spinner from "./spinner"
import { handleRecaptchaCheck } from "../scripts/utils"

// Property lookup search input and submit property data prompt

const PropertyLookup = ({ minQueryLength, debounceDelay, dropdownTop }) => {
    // Define state for autosuggest and modal
    const [inputValue, setInputValue] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [isError, setIsError] = React.useState(false)
    const [isEmpty, setIsEmpty] = React.useState(false)
    const [isSearching, setIsSearching] = React.useState(false)
    const [addressSuggestions, setSuggestions] = React.useState([])
    const [addressId, setSelectedAddressId] = React.useState(0)
    const [promptIsOpen, setPrompt] = React.useState(false)

    // Get address suggestions from the API if endpoint is defined
    const getAddressSuggestions = async({ value, reason }) => {
        if (process.env.GATSBY_API_ADDRESS_ENDPOINT) {
            if (value.length > minQueryLength && value !== inputValue) {
                // Set loading state
                setIsError(false)
                setIsEmpty(false)
                setIsLoading(true)
                return await axios
                    .get(process.env.GATSBY_API_ADDRESS_ENDPOINT, {
                        params: {
                            format: "json",
                            query: encodeURIComponent(value),
                        },
                    })
                    .then(response => {
                        // Small delay to improve the feel of the dropdown, loads too fast!
                        setTimeout(() => {
                            setIsLoading(false)
                            setIsEmpty(!Boolean(response.data.length))
                            setSuggestions(response.data)
                        }, 10)
                    })
                    .catch(error => {
                        // Error
                        setIsError(true)
                        setIsLoading(false)
                        return false
                    })
            }
        } else {
            setIsError(true)
            return false
        }
    }

    // Debounce getAddressSuggestions for performance
    const debouncedGetAddressSuggestions = React.useCallback(
        debounce(getAddressSuggestions, debounceDelay), []
    )

    // Set the selected address id
    const setSelectedAddress = (event, { suggestion }) => {
        setSelectedAddressId(suggestion.id)
    }

    // Return the value of the selected suggestion
    const addressSuggestionValue = suggestion => {
        return suggestion.address
    }

    // Define markup of suggestion
    const renderAddressSuggestion = suggestion => {
        return <span > { suggestion.address } </span>
    }

    // Define markup of suggestion dropdown
    const renderSuggestionsContainer = ({ containerProps, children }) => {
        return ( <div {...containerProps }> 
            {
                isError && !isLoading ? ( <
                    div className = "react-autosuggest__suggestions-error" >
                    There was an error. <
                    /div>
                ) : null
            }

            {
                isEmpty && !isError && !isLoading ? ( <
                    div className = "react-autosuggest__suggestions-empty" >
                    No results found. <
                    /div>
                ) : null
            } 
            {!isEmpty && !isError ? children : null  //Add the suggestions
            }
            { /* If there's no children yet append a spinner as well. Ideally we
              would change the styling a little in an unobstrustive way if there are children but we
              are loading */
              !isEmpty && !isError && isLoading && !children ? <Spinner/> : null
            }
            </div>
        )
    }

    // Clear current address suggestions state
    const clearAddressSuggestions = () => {
        setSuggestions([])
    }

    // Clear input and any selected addressId
    const clearAddressInput = () => {
        clearAddressSuggestions()
        setSelectedAddressId(0)
        setInputValue("")
    }

    // Update the inputValue state when the autosuggest input changes
    const handleInputChange = (event, { newValue }) => {
        if (!newValue.length) {
            clearAddressInput()
        } else if (addressId && newValue !== inputValue) {
            setSelectedAddressId(0)
        } else if (!isSearching) {
            setInputValue(newValue)
        }
    }

    // Toggle the open/closed state of the prompt modal
    const togglePromptModal = (event, isOpen) =>
        setPrompt(typeof isOpen !== "undefined" ? Boolean(isOpen) : !promptIsOpen)

    // Search click
    const handleSearchClick = async event => {
        event.preventDefault()
        setIsSearching(true)
        handleRecaptchaCheck("search", () => {
            setIsSearching(false)
            togglePromptModal(true)
        })
    }

    // Reset the state of the component
    const resetLookup = () => {
        togglePromptModal(false)
        setInputValue("")
        setIsLoading(false)
        setIsError(false)
        setIsEmpty(false)
        setIsSearching(false)
        setSuggestions([])
        setSelectedAddressId(0)
    }

    // Define props for the input element within react-autosuggest
    const inputProps = {
        placeholder: "Address",
        value: inputValue,
        onChange: handleInputChange,
        className: "search-field",
    }

    return ( <>
            <form className = { `home-search flex flex-sb${
          dropdownTop && " react-autosuggest__dropdown-top"
        }` } >
            <div className = "form-item" >
            <Autosuggest 
            suggestions = { addressSuggestions }
            onSuggestionsFetchRequested = { debouncedGetAddressSuggestions }
            onSuggestionsClearRequested = { clearAddressSuggestions }
            onSuggestionSelected = { setSelectedAddress }
            getSuggestionValue = { addressSuggestionValue }
            renderSuggestion = { renderAddressSuggestion }
            renderSuggestionsContainer = { renderSuggestionsContainer }
            inputProps = { inputProps }
            /> {
            inputValue && addressId ? ( 
                <button className = "search-field-clear"
                onClick = { clearAddressInput } >
                X </button>
            ) : null
        } </div> 
        <div>
        <button type = ""
    value = ""
    className = "btn btn-blue search-btn btn-block-mobile "
    onClick = { handleSearchClick }
    disabled = {!Boolean(addressId) || isSearching } > {!isSearching && < span > Search </span>} {
        isSearching && <span> Searching... </span>} 
        </button> </div> </form>

        <Modal
        isOpen = { promptIsOpen }
        toggleModal = { togglePromptModal }
        label = "Improve our data prompt" >
        <h2 > Want to help us improve our data ? </h2> 
        <div className = "improve-data-btn-row" >
        <
        Link
        to = { `/property-report/${addressId}` }
        onClick = { resetLookup }
        className = "btn btn-grey" >
        <
        span className = "top-btn-label" > No < /span> <
        span className = "bottom-btn-label" > Show me the report < /span> < /
        Link > <
        Link
        to = { `/property-data/${addressId}` }
        onClick = { resetLookup }
        className = "btn btn-orange" >
        <
        span className = "top-btn-label" > Yes < /span> <
        span className = "bottom-btn-label" > I would love to help < /span> < /
        Link > <
        /div> <
        p >
        If you do want to help,
        we ask 5 quick questions which will help us <
        br / >
        value the property more accurately <
        /p> < /
        Modal > <
        />
    )
}

PropertyLookup.defaultProps = {
    dropdownTop: false,
}

PropertyLookup.propTypes = {
    minQueryLength: PropTypes.number.isRequired,
    debounceDelay: PropTypes.number.isRequired,
    dropdownTop: PropTypes.bool,
}

export default PropertyLookup