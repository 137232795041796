import axios from "axios"
import React from "react"

export const formatAUD = value =>
  new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(value)

export const formatDate = date => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ]
  return `${months[date.getMonth()]} ${date.getFullYear()}`
}

// Probably unneeded, use the formatted address returned from the backend
export const formatAddress = (
  unit_number,
  unit_number_suffix,
  street_number,
  street_name,
  street_type,
  street_type_suffix,
  suburb,
  state,
  postcode
) => {
  let address = ""

  address += unit_number ? unit_number : ""
  address += unit_number_suffix ? unit_number_suffix : ""
  address += unit_number ? "/" : ""
  address += street_number ? street_number : " "
  address += address.length && street_name ? " " : ""
  address += street_name ? street_name : ""
  address += (address.length && street_type && street_type !== "NA_") ? " " : ""
  address += (address.length && street_type && street_type !== "NA_") ? street_type : ""
  address += address.length && suburb ? ", " : ""
  address += suburb ? suburb : ""
  address += address.length && state ? " " : ""
  address += state ? state : ""
  address += address.length && postcode ? " " : ""
  address += postcode ? postcode : ""

  return address
}

export const handleRecaptchaCheck = (action, callback) => {
  if (
    window.grecaptcha &&
    action &&
    callback &&
    typeof callback === "function"
  ) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_KEY, { action })
        .then(token =>
          axios
            .post(process.env.GATSBY_API_VERIFY_CAPTCHA_ENDPOINT, {
              token,
            })
            .then(response => {
              if (response.data.success) {
                callback()
              }
            })
            .catch(error => {
              console.error(error)
            })
        )
    })
  }
}

export function titleCase(str){
  str = str.toLowerCase().split(' ');
  let final = [ ];
   for(let  word of str){
     final.push(word.charAt(0).toUpperCase()+ word.slice(1));
   }
 return final.join(' ')
}

// Window size hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}